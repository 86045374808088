<template>
  <section v-if="selecting" class="brows-templates-section full-full-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7">
          <h1 class="main-title">
            <template v-if="isBrowsResumePage">Resume Templates</template>
            <template v-else> Cover letter templates</template>
          </h1>
          <p class="main-text">
            <template v-if="isBrowsResumePage">
              Choose from our library of HR-approved, job-specific resume
              templates that you can customize. Build a professional resume in
              just a few minutes.
            </template>

            <template v-else>
              Choose from our library of HR-approved, job-specific cover letter
              templates that you can customize. Build a professional cover
              letter in just a few minutes.
            </template>
          </p>
        </div>
      </div>

      <BrowsTemplatesFilters
        v-if="isBrowsResumePage"
        @selectFilter="getTemplates"
      />

      <div v-if="currentTemplates.length" class="row">
        <div
          v-for="template of currentTemplates"
          :key="template.id"
          class="col-6 col-lg-4 col-xl-3 template-col"
        >
          <BrowsTemplatesArticle
            :template="template"
            :isResume="isBrowsResumePage"
            @openPreview="openPreviewModal"
            @chooseTemplate="chooseTemplate"
            :skip_template="skip_template"
          />
        </div>
      </div>
    </div>

    <AppModal
      :isOpenModal="isOpenModal"
      class="template-preview-modal"
      @close="closeModal"
    >
      <BrowsTemplatesPreview
        :isResume="isBrowsResumePage"
        :template="currentPreview"
        @close="closeModal"
        @chooseTemplate="chooseTemplate"
      />
    </AppModal>
    <footer v-if="skip_template == 'on'" class="brows-templates-footer">
      <AppButton
        type="submit"
        title="Skip"
        className="secondary"
        class="brows-templates-footer__button"
        @click.prevent="skipContinue"
      />
      <AppButton
        type="submit"
        title="Continue"
        className="primary"
        class="brows-templates-footer__button"
        @click.prevent="skipContinue"
      />
    </footer>
  </section>
</template>

<script>
import BrowsTemplatesFilters from "@/components/brows-templates/BrowsTemplatesFilters";
import BrowsTemplatesArticle from "@/components/brows-templates/BrowsTemplatesArticle";
import BrowsTemplatesPreview from "@/components/brows-templates/BrowsTemplatesPreview";
import {
  BROWS_TEMPLATE_NAMES,
  CREATING_COVER_LETTER_NAMES,
  CREATING_RESUME_NAMES,
} from "@/helpers/rout-constants";
import { mapGetters } from "vuex";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import CookieHelper from "@/helpers/cookie-helper";
import AppButton from "@/components/ui/AppButton";

export default {
  name: "BrowsResumeTemplates",
  components: {
    BrowsTemplatesFilters,
    BrowsTemplatesArticle,
    BrowsTemplatesPreview,
    AppButton,
  },
  mixins: [mixpanelMixin],

  computed: {
    ...mapGetters({
      templates: "templates/resumeTemplates",
      letterTemplates: "templates/letterTemplates",
    }),

    isBrowsResumePage() {
      return this.$route.name === BROWS_TEMPLATE_NAMES.BROWS_RESUME;
    },
  },

  data() {
    return {
      isOpenModal: false,
      currentTemplates: [],
      currentPreview: null,
      skip_template: "off",
      allResumeTemplates: [],
      selecting: false,
    };
  },

  watch: {
    $route() {
      this.setTemplate();
    },
  },

  async created() {
    if (localStorage.getItem("selected_from_home_page")) {
      localStorage.removeItem("selected_from_home_page");
      history.back();
    }
    if (localStorage.getItem("template_id")) {
      localStorage.setItem("selected_from_home_page", true);
      this.chooseTemplate(localStorage.getItem("template_id"));
    } else {
      this.selecting = true;
    }
    this.skip_template = CookieHelper.getFlag("skip_template", "off");
    if (this.isBrowsResumePage) {
      this.mixpanelTrack("V2-BROWSERESUMETEMPLATES");
    } else {
      this.mixpanelTrack("V2-BROWSELETTERTEMPLATES");
    }
    await this.$store.dispatch("templates/getResumeTemplates", "all");
    this.allResumeTemplates = this.templates;
    return this.setTemplate();
  },

  methods: {
    skipContinue() {
      const currentTemplates = this.isBrowsResumePage
        ? this.allResumeTemplates
        : this.currentTemplates;
      const simpleTemplate = currentTemplates.filter(
        (template) => template.display_name === "Simple"
      );
      const templateId = simpleTemplate ? simpleTemplate[0].id : null;
      this.$router.push({
        name: this.isBrowsResumePage
          ? CREATING_RESUME_NAMES.STEP_INIT
          : CREATING_COVER_LETTER_NAMES.STEP_INIT_LETTER,
        params: { templateId },
      });
    },

    openPreviewModal(templateId) {
      const currentTemplate = this.currentTemplates.find(
        (template) => template.id === templateId
      );

      this.currentPreview = { ...currentTemplate };

      this.mixpanelTrack("V2-TEMPLATEPREVIEW", {
        templateId: this.currentPreview.id,
        templateDisplayName: this.currentPreview.display_name,
        templateName: this.currentPreview.template_name,
      });

      this.isOpenModal = true;
    },

    closeModal() {
      this.isOpenModal = false;
      this.currentPreview = null;
    },

    chooseTemplate(templateId) {
      localStorage.removeItem("template_id");
      this.$router.push({
        name: this.isBrowsResumePage
          ? CREATING_RESUME_NAMES.STEP_INIT
          : CREATING_COVER_LETTER_NAMES.STEP_INIT_LETTER,
        params: { templateId },
      });
    },

    setTemplate() {
      if (this.isBrowsResumePage) this.getTemplates("Popular");
      else {
        this.getLetterTemplates();
      }
    },

    async getTemplates(filter) {
      await this.$store.dispatch("loader/pending");
      try {
        this.currentTemplates = await this.$store.dispatch(
          "templates/getResumeTemplates",
          filter
        );
        this.currentTemplates = this.templates;
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    async getLetterTemplates() {
      await this.$store.dispatch("loader/pending");
      try {
        if (!this.letterTemplates.length)
          this.currentTemplates = await this.$store.dispatch(
            "templates/getCoverLetterTemplates"
          );
        this.currentTemplates = this.letterTemplates;
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },
  },
};
</script>

<style lang="scss" scoped>
section.brows-templates-section {
  padding-top: 40px;
  padding-bottom: 64px;
}

.main-title {
  text-align: center;
  margin-bottom: 8px;
}

.main-text {
  text-align: center;
  font-size: 18px;
  color: $light-gray;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.template-col {
  @media (max-width: map_get($grid-breakpoints, md)) {
    padding: 0 8px;
  }
}

::v-deep .template-preview-modal {
  .modal {
    padding: 20px 0;
    background-color: transparent;
    width: 400px !important;
    overflow-y: auto;
    max-height: calc(100vh - 30px);

    @media (min-width: map_get($grid-breakpoints, sm)) {
      max-height: calc(100vh - 60px);
    }

    @media (min-width: map_get($grid-breakpoints, md)) {
      width: 100% !important;
      max-width: 100%;
      border-radius: 0;
      max-height: 80%;
      display: flex;
      justify-content: center;

      .preview {
        width: 500px;
      }
    }

    @media (min-width: map_get($grid-breakpoints, lg)) {
      background-color: #fff;
      width: 900px !important;
      height: 100%;
      overflow-y: initial;
      border-radius: 16px;
      max-height: calc(100vh - 150px);
      max-width: calc(100vw - 60px);
      display: block;

      .preview {
        width: auto;
      }
    }

    @media (min-width: map_get($grid-breakpoints, xl)) {
      padding: 40px 0;
    }

    .modal__close {
      display: none;

      @media (min-width: map_get($grid-breakpoints, lg)) {
        display: inline-flex;
      }
    }

    .preview-info-col {
      @media (min-width: map_get($grid-breakpoints, lg)) {
        height: auto;
        max-height: 100%;
        overflow-y: auto;
      }
    }
  }
}

.brows-templates-footer {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
  background-color: white;
  padding: 16px 0;
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &__button {
    margin: 0 16px;
    border-radius: 50px;
    font-size: 18px;
    height: 43px;
    min-width: 150px;
    @media (max-width: 425px) {
      font-size: 14px;
    }
    @media (max-width: 320px) {
      margin: 0 10px;
      height: 38px;
      min-width: 120px;
    }
  }
}
</style>
