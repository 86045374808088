<template>
  <div class="preview">
    <div class="preview-img-col">
      <figure class="preview-img">
        <img
          :src="
            template.image_name ||
            require('@/assets/images/default-template-img.svg')
          "
          alt=""
          class="img-fit-cover absolute-full"
        />
      </figure>
    </div>

    <div class="preview-info-col">
      <div class="preview-info">
        <h2 class="preview-info__title">{{ template.display_name }}</h2>
        <p class="preview-info__text">
          {{ template.text }}
        </p>

        <div class="preview-count" v-if="1 == 0">
          <h3 class="preview-count__int">
            {{ template.used_count }}
          </h3>
          <p class="preview-count__subtext">
            {{ textOption }} created with this template
          </p>
        </div>

        <div class="preview-count" v-if="1 == 0">
          <h3 class="preview-count__int">
            {{ template.daily_count }}
          </h3>
          <p class="preview-count__subtext">{{ textOption }} created today</p>
        </div>

        <AppButton
          :title="buttonTitle"
          className="primary"
          class="preview-info__btn"
          iconName="PageIcon"
          @click.prevent="chooseTemplate"
        />
      </div>
    </div>

    <div class="preview-info-mobile">
      <AppButton
        :title="buttonTitle"
        className="primary"
        class="preview-info-mobile__btn"
        iconName="PageIcon"
        @click.prevent="chooseTemplate"
      />

      <button
        type="button"
        class="preview-info-mobile__close"
        @click.prevent="$emit('close')"
      >
        <AppIcon componentName="CloseIcon" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrowsTemplatesPreview",

  props: {
    template: {
      type: Object,
      required: true,
    },
    isResume: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    textOption() {
      return this.isResume ? "Resume" : "Cover letters";
    },
    buttonTitle() {
      return this.isResume ? "Create Resume" : "Create Cover Letter";
    },
  },

  methods: {
    chooseTemplate() {
      this.$emit("chooseTemplate", this.template.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    flex-wrap: nowrap;
    height: 100%;
    //overflow-y: auto;
  }

  @media (min-width: 1650px) and (min-height: 850px) {
    align-items: center;
  }
}

.preview-img-col {
  width: 100%;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    width: 50%;
    padding-left: 20px;
    overflow-y: auto;
    max-height: 100%;
  }

  @media (min-width: map_get($grid-breakpoints, xl)) {
    width: 58%;
    padding-left: 40px;
  }

  .preview-img {
    padding-top: 338/260 * 100%;
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      border-radius: 0;
      border: 1px solid #ddd8d8;
    }
  }
}

.preview-info-col {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .preview-info {
    width: 100%;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      padding: 0 20px;
      height: 100%;
      overflow-y: auto;
    }

    @media (min-width: map_get($grid-breakpoints, xxl)) {
      padding: 0;
      max-width: 270px;
    }

    &__title {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 16px;
    }

    &__text {
      font-size: 14px;
      color: $light-gray;
      margin-bottom: 65px;
    }

    &__btn {
      margin-top: 18px;
      width: 100%;
      padding: 0;
    }
  }
}

.preview-count {
  & + .preview-count {
    margin-top: 24px;
  }

  &__int {
    font-size: 18px;
    line-height: 140%;
  }

  &__subtext {
    font-size: 12px;
    color: $light-gray;
  }
}

.preview-info-col {
  display: none;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    display: flex;
  }
}

.preview-info-mobile {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: map_get($grid-breakpoints, sm)) {
    margin-top: 16px;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    display: none;
  }

  &__btn {
    width: 100%;
    margin-bottom: 15px;

    @media (min-width: map_get($grid-breakpoints, sm)) {
      margin-bottom: 32px;
    }
  }

  &__close {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0;

    @media (min-width: map_get($grid-breakpoints, sm)) {
      width: 40px;
      height: 40px;
    }

    svg {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background-color: $primary;

      svg {
        fill: #fff;
      }
    }
  }
}
</style>
